import React, { useEffect, useState } from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {

  const HomePage = React.lazy(() => import("./pages/home"));


  return (
    <BrowserRouter>
      <React.Fragment>
        <div id="wrapper">
        <ToastContainer
                
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{zIndex: 999999}}
              />
          <React.Suspense fallback={<p></p>}>
            <Routes>
              <Route path="/" exact element={<HomePage />} />
            </Routes>
          </React.Suspense>
        </div> 
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
